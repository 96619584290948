/* styles.css */
@font-face {
    font-family: 'Mahoda Display';
    src: url('../../fonts/mahoda_display/Mahoda\ Display\ Regular.otf') format('opentype');
}

@font-face {
    font-family: 'Coolvetica';
    src: url('../../fonts/coolvetica/coolvetica\ rg.otf') format('opentype');
}

body {
    font-family: 'Coolvetica', Arial, sans-serif;
    background-color: #fff5d6;
    color: #333;
    margin: 20px;
    padding: 20px;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: 10px;
}

/* Scrollbar styling (optional) */
.leaderboard-table-wrapper {
    -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS */
    width: 100%;
    overflow-x: auto;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    margin: 10px 0 20px;
    text-align: center;
    position: relative;
    flex-wrap: wrap;
    transition: transform 0.3s ease;
    z-index: 2;
}

.bodycontent {
    display: flex;
    flex-direction: column;
}

.header-content {
    display: flex;
    align-items: center;
}

.header-text {
    text-decoration: underline;
    text-underline-offset: 2px;
    text-decoration-thickness: 2px;
}

.section {
    position: relative;
}

.section::before {
    content: '';
    display: block;
    height: 10px;
    margin-top: -10px;
    visibility: hidden;
    pointer-events:none;
}

.logo {
    height: 90px;
    width: auto;
    margin-top: -20px;
}

.title-container {
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
}

.volley, .vibe {
    font-family: 'Mahoda Display', sans-serif;
    color: #e7552b;
    font-size: 48px;
    line-height: 1;
}

.nav-left a, .nav-right a {
    text-decoration: none;
    color: #E7552B;
    font-family: 'Coolvetica', Arial, sans-serif;
    font-size: 20px;
    margin: 15px;
    padding: 5px 20px;
    transition: color 0.3s, background-color 0.3s;
}

.nav-left a.active, .nav-right a.active {
    background-color: #E7552B;
    color: #fff5d6;
    border-radius: 20px;
}

.nav-left a.active:hover, .nav-right a.active:hover {
    background-color: #e03e00;
}

.nav-left {
    left: 0;
    position: absolute;
}

.nav-right {
    right: 0;
    position: absolute;
}

.mobile-nav {
    display: none;
    flex-direction: column;
    position: absolute;
    background-color:#E7552B;
    width: 100%;
    left: 0;
    top: 70px;
    text-align: center;
    padding: 10px;
    margin-left: -10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 9999;
    border-radius: 10px;
}

.mobile-nav a {
    color: #fff5d6;
    margin-bottom: 10px;
    text-decoration: none;
    padding: 10px
}

.mobile-nav a.active {
    background-color: #fff5d6;
    color: #E7552B;
    border-radius: 10px;
}

.return-to-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 1000;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.return-to-top-icon {
    font-size: 2rem;
    color: #fff5d6;
    background-color: rgba(231, 85, 43, 0.8);
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.return-to-top-icon:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

h1, h2, h3 {
    font-family: 'Coolvetica', sans-serif;
    color: #E7552B;
    margin-bottom: 20px;
}

button {
    background-color: #E7552B;
    color: #fff5d6;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
    font-family: 'Coolvetica', Arial, sans-serif;
    margin: 10px 0;
}

button:hover {
    background-color: #e03e00;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    border: 2px solid #E7552B;
    padding: 10px;
    text-align: left;
}

th {
    background-color: #E7552B;
    color: #fff5d6;
    cursor: pointer;
}

th .header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

th .header-text {
    white-space: nowrap;
}

form {
    margin-bottom: 20px;
}

form div {
    margin-bottom: 10px;
}

input[type="text"], input[type="number"], input[type="date"] {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #E7552B;
    border-radius: 5px;
}

ul {
    list-style-type: none;
    padding: 0;
}

/*li {
    margin-bottom: 10px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
}*/

li button {
    margin-left: 10px;
}

/* Footer Styles */
.footer {
    background-color: #e7552b;
    color: #fff5d6;
    padding: 1rem 0;
    text-align: center;
    width: calc(100% + 80px);
    height: 40px;
    margin-top: 130px;
    margin-left: -40px;
    margin-right: -40px;
    position:relative;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-content {
    max-width: 1200px;
    width: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer-content p {
    margin: 0 0 0px;
    font-family: 'Coolvetica', Arial, sans-serif;
    font-size: 16px;
}

.footer-content p a {
    color: #fff5d6;
    text-decoration: none;
    margin: 0 10px;
    transition: color 0.3s;
}

.footer-content p a:hover {
    color: #fff;
    text-decoration: underline;
}

/* Additional Styles for MatchManagement */
.teams {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.team {
    width: 45%;
    padding: 15px;
    border: 1px solid #E7552B;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.team h3 {
    font-family: 'Mahoda Display', sans-serif;
    color: #e7552b;
    font-size: 24px;
    margin-bottom: 10px;
}

.loading-indicator {
    font-size: 24px;
    color: #E7552B;
    text-align: center;
    margin: 20px 0;
}

.match-details {
    display: flex;
    flex-wrap: column;
    gap: 10px;
    margin-top: 20px;
}

.match-details label {
    font-weight: bold;
}

.edit-scores input,
.match-details input,
.match-details select {
    width: 100%;
    padding: 8px;
    margin: 5px 0;
    border: 1px solid #e7552b;
    border-radius: 4px;
}

.match-details input,
.match-details button,
.match-details select {
    flex: 1;
    min-width: 120px;
    padding: 10px;
    margin: 5px;
    box-sizing: border-box;
    border: 1px solid #E7552B;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.6);
    font-family: 'Coolvetica', Arial, sans-serif;
    color: #333;
    appearance: none;
}

.match-details select option:first-child {
    color: gray;
}

.match-details input::placeholder,
.match-details select {
    color: gray;
    font-weight: normal;
}

.match-details button {
    background-color: #E7552B;
    color: #fff5d6;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.match-details button:hover {
    background-color: #e03e00;
}

.match-details select {
    height: 42px;
}

.filter-container {
    margin-top: 40px;
}

.filter-container input {
    flex: 1;
    min-width: 120px;
    padding: 10px;
    margin: 5px;
    box-sizing: border-box;
    border: 1px solid #E7552B;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.6);
    font-family: 'Coolvetica', Arial, sans-serif;
    color: #333;
    appearance: none;
}

.sort-indicator {
    font-size: 12px;
    margin-left: 5px;
}

.table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -50px;
    padding: 10px;
    position: sticky;
    top: 0;
    background-color: #fff5d6;
    z-index: 2;
}

.table-header h2 {
    margin: 0;
    font-size: 24px;
    color: #E7552B;
}

/* Search bar styles */
.search-bar-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
    position: relative;
    margin-top: -70px; /* Add top margin for better alignment */
}

.search-input-container {
    display: flex;
    align-items: center;
    position: relative;
}

.search-input {
    width: 200px;
    padding: 100px; /* Add padding to the left for the icon */
    border: 2px solid #E7552B; /* Outline color */
    border-radius: 5px;
    background-color: #fff5d6;
    color: #E7552B;
    font-family: 'Coolvetica', Arial, sans-serif;
    transition: border-color 0.3s, background-color 0.3s;
}

.search-input::placeholder {
    color: #E7552B;
}

.search-input:focus {
    border-color: #e7552b; /* Outline color */
    background-color: #fff;
}

.search-input::selection {
    background-color: #E7552B;
    color: #fff5d6;
}

/* Ensure the h2 and search bar are aligned */
h2.match-title {
    display: inline-block;
    margin-right: 20px;
}

.search-icon {
    cursor: pointer;
    font-size: 24px;
}

.search-container {
    display: inline-block;
    margin-left: 20px;
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
}

.search-container.visible {
    height: auto;
    padding-top: 0;
    margin-bottom: -10px;
}

.search-container.hidden {
    height: 0;
    padding-top: 0;
}

.search-container input {
    padding: 10px;
    width: 200px;
    border-radius: 5px;
    border: 1px solid #E7552B;
}

.sticky-header th {
    position: sticky;
    background-color: #E7552B;
    z-index: 1;
}

.admin-confirm {
    font-size: 20px;
    color: #E7552B;
    margin: 20px 0;
}

.admin-dashboard-modal {
    width: 90%;
    max-width: 1200px;
}

.match-management-modal {
    width: 100%;
    max-width: 1200px;
}


.playerlist {
    margin-top: -16px;
}

/* styles.css */

.players-list-container {
    display: flex;
    flex-direction: column;
}

.players-list-columns {
    display: flex;
    justify-content: space-between;
}

.players-list-column {
    width: 45%;
    display: flex;
    flex-direction: column;
}

.player-checkbox {
    margin-bottom: 10px;
}


.sidebar {
    width: 250px;
    background-color: #e7552b;
    padding: 20px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
}

.sidebar nav {
    position: sticky;
    top: 0px;
}

.sidebar nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sidebar nav ul li {
    margin: 20px 0;
}

.sidebar nav ul li a {
    text-decoration: none;
    color: #fff5d6;
    font-family: 'Coolvetica', Arial, sans-serif;
    font-size: 20px;
    transition: color 0.3s;
}

.sidebar nav ul li a:hover {
    color: #fff;
}

/* Dashboard Layout */
.dashboard-layout {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.dashboard-container {
    display: flex;
    flex: 1;
    padding-left: 0px; /* Width of the sidebar */
}

.dashboard-content {
    flex: 1;
    padding-left: 20px;
    background-color: #fff5d6;
    overflow-y: auto;
}

/* Dashboard Card */
.dashboard-card {
    background-color: rgba(255, 255, 255, 0.6);
    padding: 20px;
    border: 1px solid #e7552b;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

input[type="checkbox"]:disabled + label {
    color: #999;
    cursor: not-allowed;
    text-decoration: line-through;
}

/* Grid layout for matches */
.match-grid {
    display: grid;
    gap: 20px;

}

.match-card {
    background-color: rgba(255, 255, 255, 0.6);
    padding: 10px;
    border: 1px solid #E7552B;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin: 10px;
}

.orange {
    color: #E7552B;
}

.yellow {
    color:#ffc658;
}

.brown {
    color:#977e57;
}

.name-link {
    color: black
}

.match-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-color: #E7552B;
}

.match-card div {
    margin-bottom: 10px;
}

.match-card button {
    background-color: #E7552B;
    color: #fff5d6;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.match-card button:hover {
    background-color: #e03e00;
}

.tos-header {
    font-family: 'Coolvetica', Arial;
}

/* Modal Styles */
.modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.5);
}

.modal-content {
    position: relative;
    z-index: 1001;
    background-color: #FFFBEF;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    border-radius: 10px;
    overflow-y: auto;
}

.admin-modal {
    width: 100%;
    max-height: 100vh;
    display: flex;
    flex-direction:column;
    overflow: auto;
    padding: 20px;
}

/* Keyframes for zoom-in and zoom-out animations */
@keyframes zoomIn {
    from {
        opacity: 0;
        transform: scale(0.5);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes zoomOut {
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(0.5);
    }
}

/* Keyframes for fade-in and fade-out animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* Animation classes */
.modal-zoom-in {
    animation: zoomIn 0.5s forwards;
}

.modal-zoom-out {
    animation: zoomOut 0.5s forwards;
}

.background-fade-in {
    animation: fadeIn 0.5s forwards;
}

.background-fade-out {
    animation: fadeOut 0.5s forwards;
}


.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.modal-title {
    color: #e7552b;
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

.modal-body {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    text-align: center;
}

/* Ensure modal background stays in place */
.modal-background {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
}

.team-column {
    width: 45%;
    flex: 1;
    padding: 10px;
}

.team-column.winner h3,
.team-column.winner p {
    color: #e7552b;
}

.team-column.loser h3,
.team-column.loser p {
    color: #000;
}

.team-column h3{
    font-size: 20px;
    margin-bottom: 10px;
}

.team-color p {
    margin: 5px 0;
}

.score {
    font-size: 36px;
    font-weight: bold;
}

.triangle {
    width: 0;
    height: 0;
    border-style: solid;
    margin: 0 20px;
}

.triangle-right {
    border-width: 10px 0 10px 20px;
    border-color: transparent transparent transparent #e7552b;
}

.triangle-left {
    border-width: 10px 20px 10px 0;
    border-color: transparent #E7552B transparent transparent;
}

.modal-footer {
    text-align: center;
    padding: 10px 0;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
}

.modal-footer p {
    margin: 5px 0;
}

.match-title {
    font-family: 'Coolvetica', sans-serif;
    color: #e7552b;
    font-size: 48px;
    line-height: 1;
    margin-top: 40px;
}

.player-title {
    font-family: 'Coolvetica', sans-serif;
    color: #e7552b;
    font-size: 48px;
    line-height: 1;
}

.leader-title {
    font-family: 'Coolvetica', sans-serif;
    color: #e7552b;
    font-size: 48px;
    line-height: 1;
}

.home-content {
    margin-top: -20px;
}

.admin-title {
    font-family: 'Coolvetica', sans-serif;
    color: #e7552b;
    font-size: 48px;
    line-height: 1;
    margin-top: 20px;
    margin-bottom: 5px;
}

/* Sidebar styles */
.match-management {
    display: flex;
}

/* Updated styles for FilterBar */
.filter-bar {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #E7552B;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 0px;
    margin-bottom: 20px;
    z-index: 5;
}

.hamburger {
    display: none;
    font-size: 30px;
    cursor: pointer;
    margin-right: 20px;
}

.see-more {
    text-align: center;
    margin-top: 20px;
}

.see-more-link {
    color: #E7552B;
    font-family: 'coolvetica';
    font-size: 1.2em;
    text-decoration: none;
}

.see-more-link:hover {
    text-decoration: underline;
}


.date-input {
    height: 36px; /* Match the height of the dropdown buttons */
    width: 200px; /* Increase the width to prevent text cutoff */
    padding: 0 10px; /* Adjust padding as needed */
    font-size: 16px; /* Ensure the font size matches the dropdown buttons */
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 5px 0; /* Adjust margin to ensure proper spacing */
    color: #E7552B; /* Ensure the text color matches other filters */
    background-color: #fff5d6; /* Ensure the background color matches other filters */
}

.date-input::-webkit-datetime-edit {
    color: #E7552B; /* Ensure the text color is visible */
}

.date-input::-webkit-calendar-picker-indicator {
    background: transparent;
    color: #E7552B; /* Ensure the calendar icon is visible */
    opacity: 0.5;
}

.date-input:hover {
    background-color: #E7552B; /* Ensure the background color matches the hover state of other filters */
    color: #fff5d6; /* Ensure the text color matches the hover state of other filters */
    border: 2px solid #fff5d6; /* Ensure the border color matches the hover state of other filters */
}

.date-header {
    color: #E7552B;
    font-family: 'Coolvetica', Arial, sans-serif;
    margin-bottom: 5px;
    font-size: 18px;
}

.filter-date input::placeholder {
    color: #E7552B; /* Ensure the placeholder text color matches other filters */
}

.filter-group {
    display: flex;
    align-items: center;
}

.filter-group .dropdown,
.filter-group .filter-date {
    margin-right: 10px; /* Adjust spacing between filters as needed */
    z-index: 4;
}

.filter-date input {
    margin: 0px;
    margin-right: 0px;
    height: 50px;
    width: 140px;
    background-color: #fff5d6;
    color: #E7552B;
    font-size: 14px;
    border: 2px solid #fff5d6;
}

.filter-date input:hover{
    background-color: #E7552B;
    color: rgb(255, 255, 255);
    border: 2px solid #fff5d6;
}

.filter-date input::placeholder {
    color: #E7552B; /* Ensure the placeholder text color matches other filters */
}

.filter-count {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E7552B;
    color: #fff5d6;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    font-size: 34px;
    border: none;
    transition: background-color 0.3s;
    cursor: pointer;
    margin-left: 10px;
}

.filter-controls {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    margin-left: auto;
}

.date-group {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    margin-left: -10px;
}

.filter-summary {
    position: absolute;
    top: 50px; /* Adjusted from 100% for better positioning */
    right: 0;
    background-color: #fff5d6;
    border: 1px solid #E7552B;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    padding: 10px;
    transform: translateX(-50px); /* Shifted to the left */
}

.filter-summary ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.filter-summary li {
    padding: 5px 0;
}

.dropdown {
    position: relative;
    z-index: 4;
}

.dropdown-button {
    width: 100%;
    height: 50px;
    padding: 10px;
    border: 2px solid #fff5d6;
    border-radius: 5px;
    background-color: #fff5d6;
    font-family: 'Coolvetica', Arial, sans-serif;
    color: #E7552B;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dropdown-button:hover{
    background-color: #E7552B;
    color: #fff5d6;
    border: 2px solid #fff5d6;
}

.dropdown-content {
    min-width: 100%; /* Ensure it at least covers the button width */
    width: auto; /* Allow it to expand based on the content */
    white-space: nowrap; /* Ensure names take up only one line */
    overflow: hidden;
    text-overflow: ellipsis; /* Add ellipsis if the text overflows */
    background-color: #fff5d6; /* Background color to match your design */
    border: 1px solid #E7552B; /* Border color to match your design */
    border-radius: 5px; /* Border radius to match your design */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for dropdown */
    position: absolute; /* Positioning for dropdown */
    z-index: 999; /* Ensure it appears above other elements */
}

.dropdown-content label {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;
}

.dropdown-content input[type="checkbox"] {
    margin-right: 10px;
}

.dropdown-content label:hover {
    background-color: #E7552B;
    color: #fff5d6;
}

/* Active filter styles */
.dropdown.active .dropdown-button,
.filter-date.active input {
    background-color: #E7552B;
    color: white;
}

.combos-page .players-list {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Create two even columns */
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.combos-page .player-checkbox {
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
    padding:5px 0;
}

.combos-page .player-checkbox input{
    margin-right: 5px;
}

.combos-page label {
    margin-right: 10px;
}

.combos-page .combos-list {
    display: grid;
    grid-template-columns: 1fr; /* Single column by default */
    gap: 10px;
}

.combos-page .combo-item {
    flex: 1 1 calc(50% - 20px);
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f9f9f9;
}

.combos-page .match-card {
    background-color: rgba(255, 255, 255, 0.6);
    padding: 10px;
    border: 1px solid #E7552B;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin: 10px
}

.match-card.completed {
    background-color: rgba(200, 200, 200, 0.6);
    text-decoration: line-through;
}

/* styles.css */

/* Ensure the combos-page container displays the sections in a row */
.combos-page {
    flex-direction: column;
    margin: 20px;
}

.combo-results {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
}

/* Style for player numbers list */
.player-numbers {
    flex: 1;
    margin-right: 20px;
}

.player-numbers ul {
    list-style-type: none;
    padding: 0;
}

.player-numbers li {
    padding: 5px 0;
}

/* Style for cross reference grid */
.cross-reference-grid {
    flex: 2;
    overflow-x: auto;
    align-items: center;
    justify-content: center;
}

.cross-reference-grid table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
}

.cross-reference-grid th,
.cross-reference-grid td {
    padding: 8px;
    background-color: rgba(255, 255, 255,0.6);
    color:#000;
    border: 2px solid#fff5d6;
    border-radius: 55px;
    text-align: center;
}

.overall-cv {
    color: #e7552b;
}

.cross-reference-grid th {
    font-weight: bold;
    background-color: #e7552b;
    border: 2px solid #fff5d6;
    border-radius: 15px;
    text-align: center;

    white-space: nowrap;
}


.combos-page .match-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.combos-page ul {
    list-style-type: none;
    padding: 0;
}

.combos-page ul li {
    margin-bottom: 10px;
}

.num-matchups {
    font-family: 'Coolvetica', Arial, Helvetica, sans-serif;
}
.content {
    margin-left: 25%;
    padding: 20px;
    width: 75%;
}


.timeline-element .vertical-timeline-element-content:before {
    content: '';
    display: block;
    width: 100%;
    height: 6px;
    background-color: #e7552b;
    position: absolute;
    top: 0;
    left: 0;
}

.vertical-timeline-element-title {
    color: black;
}


/* Ensure the parent container takes the full width */
.leaderboard-table-wrapper {
    width: 100%;
    overflow-x: auto; /* Allow horizontal scrolling */
}

/* Styles for the leaderboard */
.leaderlist {
    width: 100%;
    border-collapse: collapse;
}

/* Keep the navbar, filter, and footer static */
.navbar, .filter-bar, .footer {
    position: relative;
    z-index: 1;
}

.leaderlist th {
    text-align: left;
    white-space: nowrap;
    background-color: #E7552B;
    color: #fff5d6;
    cursor: pointer;
    padding-right: 10px; /* Extra space for sort indicator */
}

.leaderlist th.sorted {
    background-color: #d34725; /* Slightly darker shade */
}

.leaderlist th:nth-child(1) {
    width: 17%; /* Name column */
}

.leaderlist th:nth-child(2) {
    width: 12%; /* Games Played column */
}

.leaderlist th:nth-child(3) {
    width: 10%; /* Wins column */
}

.leaderlist th:nth-child(4) {
    width: 10%; /* Losses column */
}

.leaderlist th:nth-child(5) {
    width: 12%; /* Winning % column */
}

.leaderlist th:nth-child(6) {
    width: 11%; /* Points For column */
}

.leaderlist th:nth-child(7) {
    width: 14%; /* Points Against column */
}

.leaderlist th:nth-child(8) {
    width: 14%; /* Point Differential column */
}

.leaderlist th .header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.leaderlist th .header-text {
    white-space: nowrap;
    flex-grow: 1;
}

.leaderlist td {
    text-align: left;
}

.vertical-timeline-element-title {
    font-size: 1.25em;
    font-weight: bold;
}

.vertical-timeline-element-content {
    background: #fff5d6;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

@keyframes flipIn {
    from {
        transform: rotateX(-90deg);
        opacity: 0;
    }
    to {
        transform: rotateX(0);
        opacity: 1;
    }
}

.flip-in {
    animation: flipIn 0.5s ease-out forwards;
    transform-origin: top;
    opacity: 0; /* Ensure rows are initially hidden */
}

@media (min-width: 769px) {
    .leaderlist th.sticky-column,
    .leaderlist td.sticky-column {
        position: static; /* Disable sticky behavior on larger screens */
        z-index: initial; /* Reset z-index */
        box-shadow: none; /* Remove box shadow */
    }

    .combos-page .combos-list {
        grid-template-columns: 1fr 1fr; /* Two columns for medium and up screens */
    }
}

/* Responsive Styles */
@media (max-width: 600px) {
    .teams {
        flex-direction: column;
    }

    .team {
        width: 100%;
        margin-bottom: 20px;
    }

    .sticky-column {
        position: sticky;
        left: 0;
        background-color: #fff5d6; /* Ensure background matches your design */
        z-index: 2; /* Ensure it appears above other table content */
        box-shadow: 0px 0px 0px 1px #E7552B;
    }

    /* Ensure the first column is sticky */
    .leaderlist th.sticky-column {
        background-color: #E7552B; /* Ensure the header's background color matches */
        color: #fff5d6; /* Ensure the header's text color matches */
    }

    .leaderlist td.sticky-column {
        background-color: #fff5d6; /* Ensure the cell's background color matches */
    }

    .match-details {
        flex-direction: column;
    }

    .match-details input,
    .match-details button,
    .match-details select {
        width: 100%;
    }

    .filter-bar {
        z-index: 1;
    }

    .content {
        margin-left: 0;
        width: 100%;
    }

    .hamburger {
        display: block;
        position: absolute;
        right: 20px;
    }

    .title-container {
        display: flex;
        align-items: center;
        position: absolute;
        left: 20px;
        transform: none;
        margin-top: 0;
    }

    .header {
        justify-content: space-between;
        flex-direction: row;
    }

    .nav-left, .nav-right {
        display: none;
    }

    .sidebar-hidden-mobile {
        display: none;
    }

    .sidebar {
        display: none;
    }

    .mobile-nav {
        display: flex;
    }

    .site-content {
        transition: transform 0.3s ease;
    }

    .header.open + .site-content {
        transform: translateY(200px);
    }
}


